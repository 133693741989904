import "core-js/modules/es.array.push.js";
import { Search } from '@element-plus/icons-vue';
import { ElMessage } from "element-plus";
import 'element-plus/es/components/message/style/css'

export default {
  name: "workbench",
  components: {
    Search
  },
  data() {
    return {
      taskCount: 0,
      myTask: 0,
      yunUserList: {
        "total": 3,
        "online": 2,
        "offline": 1
      },
      yunList: [],
      overTimeBatteryList: [],
      centerDialogVisible: false,
      input: '',
      pIndex: null,
      sIndex: null,
      functionList: [],
      functionListSon: [],
      functionListSonChild: [],
      anyList: [],
      permissionList: [],
      date: '今日',
      options: ["今日", "昨日", "本月"],
      rideCount: {},
      rideFee: {},
      rideUser: {},
      ywCount: {},
      tableData: [],
      operationList: []
    };
  },
  created() {
    this.queryCommonList();
    this.getTaskCount();
    this.getYunUserList();
    this.getYunUserListD();
    this.getOverTimeTaskList();
    this.getOverTimeBatteryList();
    this.getRideFee();
    this.getRideCount();
    this.getRideUser();
    this.open();
    this.ywDynamicList();
    this.ywDynamicCount();
  },
  computed: {
    minTime() {
      return function (min) {
        let minTime = "";
        if (min == 0) {
          return "0分钟";
        } else {
          let h = Math.floor(min / 60);
          min -= h * 60;
          if (min == 0) {
            minTime = h ? h + "小时" : "";
          } else {
            minTime = (h ? h + "小时" : "") + (min ? min + "分钟" : "");
          }
          return minTime;
        }
      };
    },
    min() {
      return function (min) {
        let minTime = "";
        if (min <= 2) {
          return "刚刚";
        } else {
          let h = Math.floor(min / 60);
          min -= h * 60;
          if (h < 1) {
            minTime = min ? min + "分钟前" : "";
          } else {
            minTime = h ? h + "小时前" : "";
          }
        }
        return minTime;
      };
    }
  },
  methods: {
    goTaskList() {
      this.$router.push({
        path: '/taskPool'
      });
    },
    goMyTask() {
      this.$router.push({
        path: '/myTask'
      });
    },
    // 获取运维动态
    ywDynamicList() {
      this.$api.business.workbench.ywDynamicList().then(res => {
        this.operationList = res.data;
      });
    },
    // 获取运维动态趋势
    ywDynamicCount() {
      this.$api.business.workbench.ywDynamicCount(JSON.stringify({
        date: this.date
      })).then(res => {
        this.ywCount = res.data;
      });
    },
    // 获取常用权限
    queryCommonList() {
      this.$api.business.workbench.queryCommonList().then(res => {
        this.anyList = res.data.map(item => {
          item['img'] = require('@/assets/blue.jpg');
          return item;
        });
      });
    },
    // 管理功能
    open() {
      this.input = "";
      this.$api.business.workbench.getParentList().then(res => {
        this.functionList = res.data;
        this.init(0, this.functionList[0].id);
      });
    },
    openDialog() {
      this.centerDialogVisible = true;
      this.permissionList = this.anyList.map(item => {
        return {
          id: item.id,
          code: item.code,
          name: item.name
        };
      });
    },
    add(item, index, type) {
      if (this.permissionList.length < 8) {
        if (type === 2) {
          this.functionListSon[index].isCommon = true;
        }
        if (type === 3) {
          this.functionListSonChild[index].isCommon = true;
        }
        this.permissionList.push(item);
        this.permissionList = this.permissionList.map(item => {
          return {
            id: item.id,
            code: item.code,
            name: item.name
          };
        });
        console.log(this.permissionList);
      } else {
        ElMessage.warning('已达最大容量，添加失败');
      }
    },
    remove(item, index, type) {
      if (this.permissionList.length > 0) {
        if (type === 2) {
          this.functionListSon[index].isCommon = false;
        }
        if (type === 3) {
          this.functionListSonChild[index].isCommon = false;
        }
        this.permissionList = this.permissionList.filter(it => it.id !== item.id);
        this.permissionList = this.permissionList.map(item => {
          return {
            id: item.id,
            code: item.code,
            name: item.name
          };
        });
        console.log(this.permissionList);
      } else {
        ElMessage.warning('常用功能已为空，移除失败');
      }
    },
    save() {
      this.centerDialogVisible = false;
      this.$api.business.workbench.saveCommon(JSON.stringify({
        commonPermissionList: this.permissionList
      })).then(() => {
        this.queryCommonList();
      });
    },
    // 判断功能是否为常用功能
    ifCommon() {
      if (this.functionListSon.length > 0) {
        this.functionListSon.forEach(item => {
          item.isCommon = false;
          this.permissionList.forEach(it => {
            if (it.id === item.id) {
              item.isCommon = true;
            }
          });
        });
      }
      if (this.functionListSonChild.length > 0) {
        this.functionListSonChild.forEach(item => {
          item.isCommon = false;
          this.permissionList.forEach(it => {
            if (it.id === item.id) {
              item.isCommon = true;
            }
          });
        });
      }
    },
    // 模糊搜索功能权限
    queryByLike() {
      this.pIndex = null;
      this.sIndex = null;
      this.functionListSon = [];
      this.$api.business.workbench.queryLikeList(JSON.stringify({
        str: this.input
      })).then(res => {
        this.functionListSonChild = res.data;
        this.ifCommon();
      });
    },
    //骑行订单趋势数据
    getRideCount() {
      this.$api.business.workbench.getRideCount(JSON.stringify({
        date: this.date
      })).then(res => {
        this.rideCount = res.data;
      });
    },
    //骑行fee趋势数据
    getRideFee() {
      this.$api.business.workbench.getRideFee(JSON.stringify({
        date: this.date
      })).then(res => {
        this.rideFee = res.data;
      });
    },
    //骑行User趋势数据
    getRideUser() {
      this.$api.business.workbench.getRideUser(JSON.stringify({
        date: this.date
      })).then(res => {
        this.rideUser = res.data;
      });
    },
    getRide() {
      this.getRideCount();
      this.getRideFee();
      this.getRideUser();
      this.ywDynamicCount();
    },
    // 获取任务数量
    getTaskCount() {
      this.$api.flow.flowApi.taskList(JSON.stringify({
        definitionKey: '',
        customerPhone: '',
        page: 1,
        size: 10
      })).then(res => {
        this.taskCount = res.data.total;
      });
      this.$api.flow.flowApi.myTaskList(JSON.stringify({
        definitionKey: '',
        customerPhone: '',
        page: 1,
        size: 10
      })).then(res => {
        this.myTask = res.data.total;
      });
    },
    //获取运维任务超时列表
    getOverTimeTaskList() {
      this.$api.business.workbench.getOverTimeTaskList().then(res => {
        this.tableData = res.data;
      });
    },
    //获取电瓶取出超时列表
    getOverTimeBatteryList() {
      this.$api.business.workbench.getOverTimeBatteryList().then(res => {
        this.overTimeBatteryList = res.data;
      });
    },
    // 获取运维人员状态信息
    getYunUserList() {
      this.$api.business.workbench.getYunList().then(res => {
        console.log(res);
        this.yunUserList = res.data;
      });
    },
    // 获取运维人员详细信息
    getYunUserListD() {
      this.$api.business.workbench.getYunUserList().then(res => {
        console.log(res);
        this.yunList = res.data;
      });
    },
    changePbtn(index, id) {
      this.input = "";
      this.pIndex = index;
      this.$api.business.workbench.getSonById(JSON.stringify({
        id: id
      })).then(res => {
        this.functionListSon = res.data;
        this.changeSbtn(0, this.functionListSon[0].id);
      });
    },
    changeSbtn(index, id) {
      this.sIndex = index;
      this.$api.business.workbench.getSonById(JSON.stringify({
        id: id
      })).then(res => {
        this.functionListSonChild = res.data;
        this.ifCommon();
      });
    },
    // 管理功能 获取默认初始数据
    init(index, id) {
      this.pIndex = index;
      this.$api.business.workbench.getSonById(JSON.stringify({
        id: id
      })).then(res => {
        this.functionListSon = res.data;
        this.sIndex = index;
        this.$api.business.workbench.getSonById(JSON.stringify({
          id: this.functionListSon[0].id
        })).then(res => {
          this.functionListSonChild = res.data;
          if (this.anyList.length > 0) {
            this.permissionList = this.anyList.map(item => {
              return {
                id: item.id,
                code: item.code,
                name: item.name
              };
            });
          }
          this.ifCommon();
        });
      });
    },
    close() {
      this.open();
    },
    goPermission(item) {
      // 跳转路由
      this.$router.push({
        path: item.code
      });
    }
  }
};